//
// Layout Config
//

// Content background Color
$docs-page-bg-color: if(isDarkMode(), #151521, $gray-100) !default;

// Content Spacing
$docs-content-spacing: (
  desktop: 30px,
  // Padding for desktop mode
  tablet-and-mobile: 15px // Padding for tablet and mobile modes,,
) !default;

// Aside
$docs-aside-config: (
  z-index: 101,
  // Aside's z-index property
  padding-x: 25px,
  menu-indention: 0.75rem,
  width: 265px,
  // Aside width for desktop mode
  bg-color: if(isDarkMode(), #1e1e2d, $white),
  box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.025),
  // Aside box shadow
) !default;